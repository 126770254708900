<script>
  import { validateAndCreateSession } from "$api/user";
  import { onMount } from "svelte";
  import {
    loadClipart,
    loadImages,
    loadAllImages,
  } from "./elementmanager/interactableelements/image/images";

  export let fetchImages = false;

  onMount(async () => {
    await validateAndCreateSession();

    if (fetchImages) {
      await loadClipart();
      await loadImages();
      await loadAllImages();
    }
  });
</script>
